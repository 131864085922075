import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_cookieuniversalnuxt_0e175a7f from 'nuxt_plugin_cookieuniversalnuxt_0e175a7f' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_sentryserver_0f27419b from 'nuxt_plugin_sentryserver_0f27419b' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_ad245fda from 'nuxt_plugin_sentryclient_ad245fda' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_router_ce5d4490 from 'nuxt_plugin_router_ce5d4490' // Source: ./router.js (mode: 'all')
import nuxt_plugin_axios_c7f76e52 from 'nuxt_plugin_axios_c7f76e52' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cms_e2625954 from 'nuxt_plugin_cms_e2625954' // Source: ../node_modules/@i22/frontend-cms-utils/plugins/cms (mode: 'all')
import nuxt_plugin_axios_16500f43 from 'nuxt_plugin_axios_16500f43' // Source: ../node_modules/@i22/frontend-cms-utils/plugins/axios (mode: 'all')
import nuxt_plugin_vuexorm_6ef35062 from 'nuxt_plugin_vuexorm_6ef35062' // Source: ../node_modules/@i22/frontend-cms-utils/plugins/vuex-orm (mode: 'all')
import nuxt_plugin_consolalevel_6f981f52 from 'nuxt_plugin_consolalevel_6f981f52' // Source: ../node_modules/@i22/frontend-cms-utils/plugins/consola-level (mode: 'all')
import nuxt_plugin_i18n_021b50fe from 'nuxt_plugin_i18n_021b50fe' // Source: ../node_modules/beisheim/plugins/i18n (mode: 'all')
import nuxt_plugin_localstorage_6223ec16 from 'nuxt_plugin_localstorage_6223ec16' // Source: ../node_modules/beisheim/plugins/local-storage (mode: 'client')
import nuxt_plugin_picturefill_5a1d80be from 'nuxt_plugin_picturefill_5a1d80be' // Source: ../node_modules/beisheim/plugins/picturefill (mode: 'client')
import nuxt_plugin_swiper_20bbb91b from 'nuxt_plugin_swiper_20bbb91b' // Source: ../node_modules/beisheim/plugins/swiper (mode: 'client')
import nuxt_plugin_scrollspy_6412f1df from 'nuxt_plugin_scrollspy_6412f1df' // Source: ../node_modules/beisheim/plugins/scroll-spy (mode: 'client')
import nuxt_plugin_sticky_2091428c from 'nuxt_plugin_sticky_2091428c' // Source: ../node_modules/beisheim/plugins/sticky (mode: 'client')
import nuxt_plugin_clickoutside_37eaaf0c from 'nuxt_plugin_clickoutside_37eaaf0c' // Source: ../node_modules/beisheim/plugins/click-outside.js (mode: 'client')
import nuxt_plugin_vuetouchevents_444e39b4 from 'nuxt_plugin_vuetouchevents_444e39b4' // Source: ../node_modules/beisheim/plugins/vue-touch-events.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"layout","mode":"out-in","beforeEnter":function _beforeEnter () {
      const el = document.querySelector('.transition-rect')
      if (el) {
        el.classList.add('transition-rect--enter')
      }
      window.scrollTo(0, 0)
    },"afterEnter":function _afterEnter () {
      const el = document.querySelector('.transition-rect')
      if (el) {
        el.classList.remove(
          'transition-rect--enter',
          'transition-rect--sport',
          'transition-rect--kultur',
          'transition-rect--gesundheit',
          'transition-rect--bildung'
        )
      }
    },"beforeLeave":function _beforeLeave () {
      const el = document.querySelector('.transition-rect')
      const colorFocuses = ['bildung', 'sport', 'gesundheit', 'kultur']
      const color = colorFocuses[Math.floor(Math.random() * colorFocuses.length)]
      const colorClass = `transition-rect--${color}`
      if (el) {
        el.classList.add('transition-rect--leave', colorClass)
      }
    },"afterLeave":function _afterLeave () {
      const el = document.querySelector('.transition-rect')
      if (el) {
        el.classList.remove('transition-rect--leave')
      }
    },"appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Beisheim Stiftung","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Frontend of Stiftung"},{"hid":"og:description","name":"og:description","content":"Frontend of Stiftung"},{"hid":"og:title","name":"og:title","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_cookieuniversalnuxt_0e175a7f === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_0e175a7f(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_0f27419b === 'function') {
    await nuxt_plugin_sentryserver_0f27419b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_ad245fda === 'function') {
    await nuxt_plugin_sentryclient_ad245fda(app.context, inject)
  }

  if (typeof nuxt_plugin_router_ce5d4490 === 'function') {
    await nuxt_plugin_router_ce5d4490(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_c7f76e52 === 'function') {
    await nuxt_plugin_axios_c7f76e52(app.context, inject)
  }

  if (typeof nuxt_plugin_cms_e2625954 === 'function') {
    await nuxt_plugin_cms_e2625954(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_16500f43 === 'function') {
    await nuxt_plugin_axios_16500f43(app.context, inject)
  }

  if (typeof nuxt_plugin_vuexorm_6ef35062 === 'function') {
    await nuxt_plugin_vuexorm_6ef35062(app.context, inject)
  }

  if (typeof nuxt_plugin_consolalevel_6f981f52 === 'function') {
    await nuxt_plugin_consolalevel_6f981f52(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_021b50fe === 'function') {
    await nuxt_plugin_i18n_021b50fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localstorage_6223ec16 === 'function') {
    await nuxt_plugin_localstorage_6223ec16(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_picturefill_5a1d80be === 'function') {
    await nuxt_plugin_picturefill_5a1d80be(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_swiper_20bbb91b === 'function') {
    await nuxt_plugin_swiper_20bbb91b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollspy_6412f1df === 'function') {
    await nuxt_plugin_scrollspy_6412f1df(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sticky_2091428c === 'function') {
    await nuxt_plugin_sticky_2091428c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickoutside_37eaaf0c === 'function') {
    await nuxt_plugin_clickoutside_37eaaf0c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetouchevents_444e39b4 === 'function') {
    await nuxt_plugin_vuetouchevents_444e39b4(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
